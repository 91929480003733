import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const DefaultWrapper = ({ $style, children }) => {
    const [css] = useStyletron();
    return <div className={css({ aspectRatio: 'var(--ratio-vertical-primary)', ...$style })}>{children}</div>;
};

DefaultWrapper.propTypes = {
    $style: PropTypes.object,
    children: PropTypes.node.isRequired,
};

export default DefaultWrapper;
